<template>
  <!--
    The view for the ActionCam Configuration
  -->
  <div class="installationDetail_WorkNotification">
    <template>
      <div class="card">
        <NotificationSwitzerland
          :installation-id="installationId"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailWorkNotification",
  components: {
    NotificationSwitzerland: () => import('@/components/Installation/NotificationSwitzerland.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  metaInfo () {
    return {
      title: 'WorkNotification'
    }
  }
}
</script>